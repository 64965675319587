

* {
  font-family: "Roboto Mono";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Remove background and height styles from .App */
.App {
  font-family: "Roboto Mono";
  text-align: center;
}

html, body {
  background: #f8f8ff;
  /* background: linear-gradient(#f8f8ff, #ffffff); */
  height: 100%;
  margin: 0;
  padding: 0;
}

.navbar .nav-links {
  width: 100%;
  /* display: flex; */
  /* justify-content: space-around; */
  list-style: none;
}

.navbar .nav-links > a {
  color: #fff;
  text-decoration: none;
}

li.home-li a {
  display: block;
  color: #000;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  float: left;
}

li.nav-li a {
  display: block;
  color: #000;
  text-align: center;
  padding: 16px;
  text-decoration: none;
  float: right;
}

li a:hover {
  background-color: #cfcfcf;
}

.card {
  margin-bottom: 2rem;
}

.grid-container {
  display: grid;
  grid-gap: 5px;
  width: 90%;
}

.graph-margins {
  margin-bottom: 70;
  margin-top: 10;
  margin-left: 70;
  margin-right: 150;
}

.low-percentage {
  color: #9d4040;
}

.mid-low-percentage {
  color: #e47363;
}

.mid-percentage {
  color: #000000;
}

.mid-high-percentage {
  color: #74ac63;
}

.high-percentage {
  color: #2e581b;
}

.low-amt {
  color: #9d4040;
  text-align: right !important;
}

.high-amt {
  color: #2e581b;
  text-align: right;
}

.sign-short {
  background-color: #f0e2e2;
  color: #000;
  
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
}

.sign-long {
  background-color: #e3efdd;
  color: #000;
  
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
}

.positive-highlight,
.positive-highlight-open,
.positive-highlight-increase,
.positive-highlight-reduce,
.positive-highlight-close {
  background-color: #6a9d50;
  color: #fff;
  
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  /* padding-top: 1px;
  padding-bottom: 1px; */
}

.negative-highlight,
.negative-highlight-increase,
.negative-highlight-reduce,
.negative-highlight-open,
.negative-highlight-close {
  background-color: #b66d6d;
  color: #fff;
  
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  /* padding-top: 1px;
  padding-bottom: 1px; */
}

.direction-positive-highlight,
.direction-positive-highlight-open,
.direction-positive-highlight-close {
  background-color: #0000ff;
  color: #fff;
  
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  /* padding-top: 1px;
  padding-bottom: 1px; */
}

.direction-positive-highlight-increase,
.direction-positive-highlight-reduce {
  background-color: #7171ff;
  color: #fff;
  
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 5px;
  /* padding-top: 1px;
  padding-bottom: 1px; */
}

.direction-negative-highlight,
.direction-negative-highlight-open,
.direction-negative-highlight-close {
  background-color: #000000;
  color: #fff;
  
  padding-left: 5px;
  padding-right: 5px;
  /* padding-top: 1px;
  padding-bottom: 1px; */
}


.direction-negative-highlight-increase,
.direction-negative-highlight-reduce {
  background-color: #949494;
  color: #fff;
  
  padding-left: 5px;
  padding-right: 5px;
  /* padding-top: 1px;
  padding-bottom: 1px; */
}

.light-gray-highlight {
  background-color: #000;
  color: #fff;
  font-weight: 500;
  padding-left: 5px;
  padding-right: 5px;
}

.trade-short {
  background-color: rgb(255, 247, 247) !important;
}

.trade-long {
  background-color: rgb(250, 255, 246) !important;
}

.net-amount-column {
  text-align: right !important;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.2rem 0.5rem !important;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.2rem 0.5rem !important;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.2rem 0.5rem !important;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.2rem 0.5rem !important;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.2rem 0.5rem !important;
}
.sub-menu-button {
  margin: 0 5px 5px 0;
  display: inline-block;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 0.5rem !important;
  height: 0 !important;
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.9rem !important;
  color: #495057;
  background: #ffffff;
  padding: 0.75rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
}

.logout-button,
.login-button {
  border: none;
  background: none;
  box-shadow: none;
  cursor: pointer;
}

.wrapper {
  height: 300px;
  position: relative;
}

.wrapperLarge {
  height: 600px;
  position: relative;
  margin-bottom: 2%;
}

.graphContainer {
  height: 300px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}

.graphContainerLarge {
  height: 600px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}

.secondGraph {
  height: 300px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
}

.legend-label {
  transform: rotate(180deg);
}

.row-primary{
  background-color: transparent !important;
}

.sticky-header-table .p-datatable-thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f8f8f8; /* Customize the background color as needed */
}

.link-no-style {
  color: inherit;
  text-decoration: none;
}
